import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import {
  blogPostContainer,
  blogPost,
  blogPostContent,
} from "./blog.module.css"
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout pageTitle={frontmatter.title}>
      <div className={blogPostContainer}>
        <div className={blogPost}>
          <div
            className={blogPostContent}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }`

export const Head = ({data}) => (
  <>
    <title>{data.markdownRemark.frontmatter.title}</title>
  </>
)
